import store from 'store';
import { navigate } from 'gatsby'
import { Auth } from 'aws-amplify';

const PATH_PARAM_REGEX = /:([a-z_]\w*)/gi;

const addQuerystringToPath = (path, qs = {}) => {
  const querystring = Object.keys(qs).reduce((acc, k) => (
    acc
      ? `${acc}&${k}=${qs[k]}`
      : `${k}=${qs[k]}`
  ), '');
  return querystring ? `${path}?${querystring}` : path;
};

const getTenantId = (params) => {
  if (params.tenantId !== undefined) return params.tenantId;
  const settings = store.get('settings');
  if (!settings) {
    Auth.signOut();
    return navigate('/login');
  }
  return settings.tenantId !== undefined
    ? settings.tenantId
    : 'me';
}

const getStoreId = (params) => {
  if (params.storeId !== undefined) return params.storeId;
  const settings = store.get('settings');
  if (!settings) {
    Auth.signOut();
    return navigate('/login');
  }
  if (settings.storeId === undefined) {
    return navigate('/select-store');
  }
  return settings.storeId;
}

/**
 * Injects path parameters into a URI from a params object.
 * @param _path The path in which to replace parameters
 * @param _params Map of parameters
 * @return {{path: string, params: object}} An object with `path` and `params` properties. `path`
 *     is the new path, and `params` is an object containing all original parameters except those
 *     injected into the path.
 * @throws Error if there exists a path parameter that is not specified in the params object.
 */
/* eslint-disable-next-line import/prefer-default-export */
export const injectPathParams = (_path, _params = {}) => {
  const { qs, ...params } = _params;
  const path = addQuerystringToPath(_path, qs);
  
  const matches = path.match(PATH_PARAM_REGEX);
  if (!matches) return { path, params };

  return matches.reduce((acc, match) => {
    const key = match.substring(1);
    const value = (
      (key === 'tenantId' && getTenantId(params)) 
      || (key === 'storeId' && getStoreId(params)) 
      || params[key]
    );
    const newPath = acc.path.replace(match, value || '');
    delete params[key];
    return {
      path: newPath,
      params,
    };
  }, { path, params });
};

/**
 * Creates an endpoint that uniquely identifies an API endpoint by its apiName, method and path.
 * @param apiName Api name (e.x. 'orders', etc.).
 * @param method HTTP method (e.x. 'get', 'post', etc.).
 * @param path Path to the endpoint, example: '/'.
 */
export function endpoint(apiName, method, path) {
  if (!apiName || !method || !path) {
    throw new Error('Endpoint descriptors must specify an apiName, a method and a path.');
  }

  return {
    apiName,
    method,
    path,
  };
}
