import { useState, useEffect } from "react"
import Amplify, { Auth } from 'aws-amplify';

import amplifyConfig from '../amplify';
import { EP, callApi } from '../api';


// TODO: tenant customisation example data: 
// { 
//   appBarBackgroundColor: '#A0A0A0',
//   badgeColor: '#ffffff',
//   badgeBackgroundColor: '#df0b6a',
//   secondaryMain: '#b4b4b4',
//   secondaryDark: '#A0A0A0',
//   secondaryLight: '#e0e0e0',
//   primaryMain: '#df0b6a',
//   primaryDark: '#8f0a47',
// }

function useTenantData() {
  const [tenantData, setTenantData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (!tenantData && !loading && !error) {
      async function auth() {
        try {
          setLoading(true);
          setError();
          const config = amplifyConfig();
          Amplify.configure(config);
          await Auth.currentCredentials();
          const domain = typeof window !== undefined && window.location.origin;
          const res = await callApi(EP.tenants.anonGet, { domain: encodeURIComponent(domain) });
          setTenantData(res);
        } catch (error) {
          console.log(error);
          setError(error.message || 'Could not login');
        } finally {
          setLoading(false);
        }
      }
      auth();
    }
  }, [tenantData, loading, error]);

  return { tenantData, loading, error };
}

export default useTenantData;
