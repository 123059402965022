import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from 'react-i18next';
import en from '../locales/en';
import frCA from '../locales/fr-CA';

export const languages = [
  { code: "en", label: "English" },
  { code: "fr-CA", label: "Français (CA)" },
]

const resources = {
  en,
  "fr-CA": frCA
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
