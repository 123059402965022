import { API } from 'aws-amplify';

import { endpoint, injectPathParams } from './utils/urlUtils';

export const API_NAMES = {
  curbsideAuth: 'curbsideAuth',
  curbsideAnon: 'curbsideAnon',
  usersAuth: 'usersAuth',
  usersAnon: 'usersAnon',
}

export const EP = {
  orders: {
    anonGet: endpoint(API_NAMES.curbsideAnon, 'get', '/orders/:sid'),
    anonPost: endpoint(API_NAMES.curbsideAnon, 'post', '/tenants/:tenantId/stores/:storeId/orders/:orderId/confirm'),
    anonPatch: endpoint(API_NAMES.curbsideAnon, 'get', '/tenants/:tenantId/stores/:storeId/orders/:orderId'),
    list: endpoint(API_NAMES.curbsideAuth, 'get', '/tenants/:tenantId/stores/:storeId/orders'),
    patch: endpoint(API_NAMES.curbsideAuth, 'patch', '/tenants/:tenantId/stores/:storeId/orders/:orderId'),
    post: endpoint(API_NAMES.curbsideAuth, 'post', '/tenants/:tenantId/stores/:storeId/orders'),
  },
  stores: {
    delete: endpoint(API_NAMES.curbsideAuth, 'del', '/tenants/:tenantId/stores/:storeId'),
    get: endpoint(API_NAMES.curbsideAuth, 'get', '/tenants/:tenantId/stores/:storeId'),
    list: endpoint(API_NAMES.curbsideAuth, 'get', '/tenants/:tenantId/stores'),
    patch: endpoint(API_NAMES.curbsideAuth, 'patch', '/tenants/:tenantId/stores/:storeId'),
    post: endpoint(API_NAMES.curbsideAuth, 'post', '/tenants/:tenantId/stores'),
  },
  users: {
    delete: endpoint(API_NAMES.usersAuth, 'del', '/tenants/:tenantId/users/:username'),
    get: endpoint(API_NAMES.usersAuth, 'get', '/tenants/:tenantId/users/:username'),
    list: endpoint(API_NAMES.usersAuth, 'get', '/tenants/:tenantId/users'),
    patch: endpoint(API_NAMES.usersAuth, 'patch', '/tenants/:tenantId/users/:username'),
    post: endpoint(API_NAMES.usersAuth, 'post', '/tenants/:tenantId/users'),
  },
  tenants: {
    anonGet: endpoint(API_NAMES.usersAnon, 'get', '/tenants/domain/:domain'),
    restoreRoot: endpoint(API_NAMES.usersAuth, 'post', '/restoreroot'),
    get: endpoint(API_NAMES.usersAuth, 'get', '/tenants/:tenantId'),
    list: endpoint(API_NAMES.usersAuth, 'get', '/tenants'),
    patch: endpoint(API_NAMES.usersAuth, 'patch', '/tenants/:tenantId'),
    post: endpoint(API_NAMES.usersAuth, 'post', '/tenants'),
  },
}

export const callApi = async (endpoint, params = {}) => {
  const { apiName, method, path } = endpoint;
  const { body, ...otherParams } = params;
  const { path: pathWithParams } = injectPathParams(path, otherParams);
  return ['post', 'patch'].includes(method) 
    ? API[method](apiName, pathWithParams, { body })
    : API[method](apiName, pathWithParams);
}
