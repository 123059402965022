import { createMuiTheme } from '@material-ui/core/styles';

const CUSTOMIZATION_ENABLED = false;

// A custom theme for this app
const theme = tenantData => {
  if (!CUSTOMIZATION_ENABLED) {
    return createMuiTheme({
      palette: {
        primary: {
          main: '#1f2e78',
          dark: '#040b4f',
        },
        background: {
          default: '#fff',
        }
      },
      custom: {},
    })
  }
  if (!tenantData) return createMuiTheme({ custom: {} });
  return createMuiTheme({
    palette: {
      secondary: Object.assign(
        {},
        tenantData.secondaryMain ? { main: tenantData.secondaryMain } : {},
        tenantData.secondaryLight ? { light: tenantData.secondaryLight } : {},
        tenantData.secondaryDark ? { dark: tenantData.secondaryDark } : {},
      ),
      primary: Object.assign(
        {},
        tenantData.primaryMain ? { main: tenantData.primaryMain } : {},
        tenantData.primaryLight ? { light: tenantData.primaryLight } : {},
        tenantData.primaryDark ? { dark: tenantData.primaryDark } : {},
      ),
      background: Object.assign(
        {},
        tenantData.backgroundColor ? { default: tenantData.backgroundColor } : {},
      ),
    },
    custom: {
      appBar: Object.assign(
        {},
        tenantData.appBarBackgroundColor ? { backgroundColor: tenantData.appBarBackgroundColor } : {},
      ),
      badge: Object.assign(
        {},
        tenantData.badgeColor ? { color: tenantData.badgeColor } : {},
        tenantData.badgeBackgroundColor ? { backgroundColor: tenantData.badgeBackgroundColor } : {},
      ),
    },
  });
}

export default theme;
