export default {
  common: {
    closed: 'Fermée',
    confirm: 'Confirmer',
    dismiss: 'Rejeter',
    error: 'Erreur',
    info: 'Info',
    success: 'Succès',
    warning: 'Attention',
    STATUS_CUSTOMER_NOTIFIED: 'Client averti',
    STATUS_CUSTOMER_ARRIVED: 'Le client est arrivé',
    STATUS_CARRIED_OUT: 'Commande en route',
    STATUS_DELIVERED: 'Commande ramassé',
    STATUS_CANCELLED: 'Commande annulée',
  },
  confirm: {
    additionalDetails: 'Détails supplémentaires',
    callTheStore: 'Si vous n\'attendez plus, veuillez appeler le magasin au:',
    carColour: 'Couleur du véhicule',
    carMake: 'Marque de véhicule',
    carModel: 'Modèle',
    confirmCancelled: 'Cette commande est annulée. Si vous avez des questions, veuillez contacter notre service client.',
    confirmSuccess: '<p>Nous serons bientôt avec votre commande.</p><p>Veuillez avoir votre photo d\'identité prête.</p></p>Si vous souhaitez que votre commande soit dans votre coffre, veuillez l\'ouvrir et notre employé la placera là-dedans.',
    confirmThanks: 'Merci d\'utiliser notre service.',
    cta: 'Confirmer l\'arrivée',
    ctaLocationFailed: 'Mettre à jour ma position',
    details: 'Comment pouvons-nous vous localiser?',
    detailsYouProvided: 'Détails que vous avez fournis:',
    errorBody: 'Une erreur s\'est produite, veuillez réessayer. Si l\'erreur persiste, contactez-nous.',
    errorTitle: 'Erreur',
    methodType: 'Comment arrivez-vous?',
    methodCar: 'En voiture',
    methodOther: 'Par d\'autres moyens',
    order: 'COMMANDE',
    plateNumber: 'Numéro de plaque',
    provideAdditionalDetails: 'Fournissez des détails supplémentaires:',
    sorry: 'Désolé',
    submitting: 'En cours...',
    thanks: 'Merci',
    title: 'Comment pouvons-nous vous localiser',
    titleLocationFailed: 'Nous vous recherchons',
    subtitle: 'S\'il vous plaît, veuillez fournir des détails sur votre véhicule ou sur la meilleure façon de vous localiser si vous voyagez par d\'autres moyens:',
    subtitleLocationFailed: 'Nous semblons avoir du mal à vous localiser. Veuillez fournir des détails supplémentaires sur la façon dont nous pouvons vous trouver.',
  },
  header: {
    addOrder: '+Commande',
    filter: 'Filtrer',
    logOut: 'Se déconnecter',
    newCustomerArrived: 'Un nouveau client est arrivé',
    newCustomerArrivedPlural: 'nouveaux clients sont arrivés',
    orders: 'Commandes',
    ordersNeedAttention: 'ordres ont besoin d\'attention',
    selectStore: 'Sélectionnez magasin',
    selectTenant: 'Select locataire',
    stores: 'Magasins',
    users: 'Utilisateurs',
    tenants: 'Locataires',
    waiting: '+10 min. attendre',
  },
  login: {
    completePassword: 'Veuillez changer votre mot de passe',
    cta: 'Suivant',
    ctaCompletePassword: 'Changer le mot de passe',
    errorFetchingStores: 'Erreur lors de la récupération des magasins',
    iForgotPassword: 'J\'ai oublié mon mot de passe',
    logIn: 'Connexion',
    logOut: 'Se déconnecter',
    noStores: 'Aucun magasin créé',
    password: 'Mot de passe',
    selectStore: 'Sélectionnez magasin',
    sending: 'En cours...',
    store: 'Magasin',
    username: 'Nom d\'utilisateur',
  },
  orderForm: {
    addOrder: 'Ajouter une commande',
    cancel: 'Annuler',
    cta: 'Ajouter',
    customerName: 'Nom du client',
    emailAddress: 'Adresse électronique',
    genericError: 'Une erreur est survenue, veuillez réessayer. <br /> Si l\'erreur persiste, contactez l\'administrateur.',
    mobilePhone: 'Téléphone portable',
    numItems: 'Nombre de colis',
    orderNumber: 'Numéro de commande',
    preferredLanguage: 'Langue préférée du client',
    sending: 'En cours...',
    smsEnabled: 'Informer le client par SMS',
    storedLocation: 'Emplacement stocké',
  },
  ordersList: {
    errorFetchingOrders: 'Une erreur s\'est produite lors de la récupération des commandes',
    filteringBy: 'Filtrage par',
    noOrders: 'Pas de commandes',
  },
  orderItem: {
    actionCancel: 'Annuler',
    actionCarryOut: 'EN ROUTE',
    actionDelivered: 'RAMASSÉ',
    actionUnableToLocate: 'Impossible à trouver',
    ago: 'depuis',
    cancelTitle: 'Annuler la commande #{{id}}?',
    cancelDescription: 'Veuillez confirmer l\'annulation de la commande #{{id}}. Un message sera envoyé au client.',
    carryOutTitle: 'Prêt à exécuter la commande #{{id}}?',
    carryOutBody: 'Veuillez confirmer. Le client sera informé que vous êtes en route',
    customerDetails: 'Détails du client',
    deliveryTitle: 'Commande #{{id}} ramassé?',
    items: '{{count}} colis',
    items_plural: '{{count}} colis',
    deliveryDescription: 'Veuillez confirmer que la commande #{{id}} a été correctement ramassé. Un email vous sera envoyé confirmant la collecte et remerciant le client.',
    locationFailedTitle: 'Impossible à trouver #{{id}}?',
    locationFailedDescription: 'Confirmez que vous ne pouvez pas localiser le client avec la commande #{{id}}. Une demande de plus de détails sera envoyée au client.',
    license: 'Plaque',
    unableToLocate: 'Impossible à trouver',
    updateError: 'N\'a pas pu mettre à jour le status',
    STATUS_SHORT_CUSTOMER_NOTIFIED: 'Averti',
    STATUS_SHORT_CUSTOMER_ARRIVED: 'Arrivé',
    STATUS_SHORT_CARRIED_OUT: 'En route',
    STATUS_SHORT_DELIVERED: 'Ramassé',
    STATUS_SHORT_CANCELLED: 'Annulée',
  },
  storeForm: {
    addAnotherStore: 'Ajouter un autre magasin',
    cancel: 'Annuler',
    emailEnabled: 'Activer les notifications par e-mail lorsque le client arrive',
    errorSaving: 'Erreur lors de l\'enregistrement du magasin',
    labelName: 'Nom',
    labelPhone: 'Téléphone (Affiché sur les courriels)',
    labelAddress1: 'Addresse Ligne 1 (Affiché sur les courriels)',
    labelAddress2: 'Addresse Ligne 2 (Affiché sur les courriels)',
    labelCoupon: 'Coupon',
    labelSchedule1: 'Horaire Ligne 1 (Affiché sur les courriels)',
    labelSchedule2: 'Horaire Ligne 2 (Affiché sur les courriels)',
    labelSchedule3: 'Horaire Ligne 3 (Affiché sur les courriels)',
    messageSuccess: 'Magasin enregistrée avec succès',
    notificationEmail: 'Adresse e-mail des notifications',
    notificationPhone: 'Numéro de téléphone des notifications',
    seeAllStores: 'Voir tous les magasins',
    save: 'Sauver',
    saving: 'En cours...',
    smsEnabled: 'Activer les notifications par SMS lorsque le client arrive',
    titleAdd: 'Ajouter un magasin',
    titleEdit: 'Modifier le magasin',
  },
  storesList: {
    addStore: 'Ajouter un magasin',
    confirmDelete: 'Veuillez confirmer la suppression du magasin {{name}} (@{{id}}).',
    errorFetch: 'Une erreur s\'est produite lors de la récupération des magasins.',
    errorDelete: 'Impossible de supprimer le magasin',
    deleteStore: 'Supprimer le magasin @{{id}}?',
    deleteSuccess: 'Store supprimé avec succès',
    noStores: 'Il n\'y a pas encore de magasins',
  },
  tenantForm: {
    addAnotherTenant: 'Ajouter un autre locataire',
    addTenant: 'Ajouter un locataire',
    cancel: 'Annuler',
    editTenant: 'Modifier le locataire',
    labelAccount: 'Compte',
    labelCoupon: 'Coupon',
    labelDescription: 'Description',
    labelDomain: 'Domaine',
    labelDisplayName: 'Nom d\'affichage',
    labelLogoUrl: 'URL du logo (inclure http:// or https://)',
    labelOwnerEmail: 'Courriel du propriétaire du locataire',
    labelOwnerFirstname: 'Prénom du propriétaire du locataire',
    labelOwnerLastname: 'Nom du propriétaire du locataire',
    labelOwnerUsername: 'Nom d\'utilisateur du propriétaire du locataire',
    labelPrivacyUrl: 'URL confidentialité (inclure http:// or https://)',
    labelLogoUrl: 'Logo URL (inclure http:// or https://)',
    labelPrivacyUrl: 'Privacy URL (inclure http:// or https://)',
    labelSocialFacebook: 'Facebook (inclure http:// or https://)',
    labelSocialInstagram: 'Instagram (inclure http:// or https://)',
    labelSocialLinkedin: 'Linkedin (inclure http:// or https://)',
    labelSocialTwitter: 'Twitter (inclure http:// or https://)',
    labelSenderEmail: 'Courriel de l\'expéditeur',
    save: 'Sauver',
    saving: 'En cours...',
    seeAllTenants: 'Voir tous les locataires',
    successMessage: 'Le locataire a été enregistré avec succès',
  },
  userForm: {
    addAnotherUser: 'Ajouter un autre utilisateur',
    cancel: 'Annuler',
    errorFetchingStores: 'Erreur lors de la récupération des magasins',
    errorSaving: 'Erreur lors de l\'enregistrement du utilisateur',
    labelAllStores: 'Tous les magasins',
    labelChooseStores: 'Choisissez des magasins (max.3)',
    labelEmail: 'Adresse électronique',
    labelFirstname: 'Prénom',
    labelLastname: 'Nom de famille',
    labelNone: 'Aucune',
    labelRole: 'Rôle',
    labelStore: 'Magasin',
    labelStores: 'Magasins',
    labelUsername: 'Nom d\'utilisateur',
    messageSuccess: 'L\'utilisateur a été enregistré avec succès',
    roleAdmin: 'Admin',
    roleEmployee: 'Employé',
    roleOwner: 'Propriétaire',
    seeAll: 'Voir tous les utilisateurs',
    save: 'Sauver',
    saving: 'En cours...',
    titleAdd: 'Ajouter un utilisateur',
    titleEdit: 'Modifier l\'utilisateur',
  },
  usersList: {
    addUser: 'Ajouter un utilisateur',
    confirmDelete: 'Veuillez confirmer la suppression de l\'utilisateur {{firstName}} {{lastName}} (@{{username}}).',
    deleteUser: 'Supprimer l\'utilisateur @{{username}}?',
    errorDelete: 'Impossible de supprimer l\'utilisateur',
    errorFetch: 'Une erreur s\'est produite lors de la récupération des utilisateurs.',
    deleteSuccess: 'L\'utilisateur a bien été supprimé',
    noUsers: 'Il n\'y a pas encore d\'utilisateur',
    roleAdmin: 'Admin',
    roleEmployee: 'Employé',
    roleOwner: 'Propriétaire',
    roleSysAdmin: 'System Admin',
  },
};
