import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

const Copyright = () => {
  const classes = useStyles();
  return  (
    <Typography variant="body2" color="textSecondary" align="center" className={classes.root}>
      {'Copyright © '}
      <Link color="inherit" href="https://pivotree.com/">
        Pivotree
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default Copyright;
