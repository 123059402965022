export const ORDER_STATUS = {
  customerNotified: 'CUSTOMER_NOTIFIED',
  customerArrived: 'CUSTOMER_ARRIVED',
  carriedOut: 'CARRIED_OUT',
  delivered: 'DELIVERED',
  cancelled: 'CANCELLED',
};

export const USER_ROLES = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  TENANT_OWNER: 'TENANT_OWNER',
  TENANT_ADMIN: 'TENANT_ADMIN',
  TENANT_USER: 'TENANT_USER',
};
