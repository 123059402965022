export default {
  common: {
    closed: 'Closed',
    confirm: 'Confirm',
    dismiss: 'Dismiss',
    error: 'Error',
    info: 'Info',
    success: 'Success',
    warning: 'Warning',
    STATUS_CUSTOMER_NOTIFIED: 'Customer notified',
    STATUS_CUSTOMER_ARRIVED: 'Customer arrived',
    STATUS_CARRIED_OUT: 'Carried out',
    STATUS_DELIVERED: 'Picked up',
    STATUS_CANCELLED: 'Cancelled',
  },
  confirm: {
    additionalDetails: 'Additional Details',
    callTheStore: 'If you are no longer waiting, please call the store at:',
    carColour: 'Car Color',
    carMake: 'Car Make',
    carModel: 'Car Model',
    confirmCancelled: 'This order is cancelled. If you have any questions, please contact our Customer Support.',
    confirmSuccess: '<p>We will be out with your order shortly.</p><p>Please have your Photo ID ready.</p><p>If you\'d like your order in your trunk, please pop it open and our employee will place it in there.</p>',
    confirmThanks: 'Thanks for using our service.',
    cta: 'Confirm Arrival',
    ctaLocationFailed: 'Update my Location',
    details: 'How can we locate you?',
    detailsYouProvided: 'Details you provided:',
    errorBody: 'An error occured, please try again. If the error persists, contact us.',
    errorTitle: 'Error',
    methodType: 'How are you arriving?',
    methodCar: 'By car',
    methodOther: 'By other means',
    order: 'ORDER',
    plateNumber: 'Plate Number',
    provideAdditionalDetails: 'Provide additional details:',
    sorry: 'Sorry',
    submitting: 'Submitting...',
    thanks: 'Thanks',
    title: 'How to Locate You',
    titleLocationFailed: 'We\'re looking for you',
    subtitle: 'Please provide some details about your vehicle, or how we can best locate you if you\'re if travelling by other means:',
    subtitleLocationFailed: 'We seem to be having some difficulty locating you. Please provide some additional details on how we can find you.',
  },
  header: {
    addOrder: '+Order',
    filter: 'Filter',
    logOut: 'Log out',
    newCustomerArrived: 'New customer has arrived',
    newCustomerArrivedPlural: 'new customers have arrived',
    orders: 'Orders',
    ordersNeedAttention: 'orders need attention',
    selectStore: 'Select store',
    selectTenant: 'Select tenant',
    stores: 'Stores',
    users: 'Users',
    tenants: 'Tenants',
    waiting: '+10 min. waiting',
  },
  login: {
    completePassword: 'Please change your password',
    cta: 'Log in',
    ctaCompletePassword: 'Change Password',
    errorFetchingStores: 'Error fetching stores',
    iForgotPassword: 'I forgot my password',
    logIn: 'Log in',
    noStores: 'There are no stores created',
    password: 'Password',
    selectStore: 'Select Store',
    sending: 'Sending...',
    store: 'Store',
    username: 'Username',
  },
  orderForm: {
    addOrder: 'Add Order',
    cancel: 'Cancel',
    cta: 'Add Order',
    customerName: 'Customer Name',
    emailAddress: 'Email Address',
    genericError: 'An error occured, please try again.<br />If the error persists, contact the administrator.',
    mobilePhone: 'Mobile Phone',
    numItems: 'Number of Packages',
    orderNumber: 'Order Number',
    preferredLanguage: 'Customer Preferred Language',
    sending: 'Creating...',
    smsEnabled: 'Notify customer through SMS',
    storedLocation: 'Stored Location',
  },
  ordersList: {
    errorFetchingOrders: 'There was an error retrieving orders',
    filteringBy: 'Filtering by',
    noOrders: 'No orders',
  },
  orderItem: {
    actionCancel: 'Cancel',
    actionCarryOut: 'CARRY OUT',
    actionDelivered: 'PICKED UP',
    actionUnableToLocate: 'Unable to locate',
    ago: 'ago',
    cancelTitle: 'Cancel order #{{id}}?',
    cancelDescription: 'Please confirm cancel order #{{id}}. A message will be sent to the customer.',
    carryOutTitle: 'Ready to carry out order #{{id}}?',
    carryOutBody: 'Please confirm. The customer is about to be notified that you are on your way.',
    customerDetails: 'Customer Details',
    deliveryTitle: 'Order #{{id}} picked up?',
    deliveryDescription: 'Please confirm order #{{id}} has been correctly picked up. An email confirming pickup and thanking the customer will now be sent.',
    locationFailedTitle: 'Unable to locate #{{id}}?',
    locationFailedDescription: 'Please confirm you are are unable to locate the customer with order #{{id}}. A request for more details will be sent to the customer.',
    license: 'License',
    items: '{{count}} Package',
    items_plural: '{{count}} Packages',
    unableToLocate: 'Unable to locate',
    updateError: 'Could not update status',
    STATUS_SHORT_CUSTOMER_NOTIFIED: 'Notified',
    STATUS_SHORT_CUSTOMER_ARRIVED: 'Arrived',
    STATUS_SHORT_CARRIED_OUT: 'Carried out',
    STATUS_SHORT_DELIVERED: 'Picked up',
    STATUS_SHORT_CANCELLED: 'Cancelled',
  },
  storeForm: {
    addAnotherStore: 'Add another store',
    cancel: 'Cancel',
    emailEnabled: 'Enable email notifications when customer arrives',
    errorSaving: 'Error saving store',
    labelName: 'Name',
    labelPhone: 'Phone (Appears on emails)',
    labelAddress1: 'Address Line 1 (Appears on emails)',
    labelAddress2: 'Address Line 2 (Appears on emails)',
    labelCoupon: 'Coupon',
    labelSchedule1: 'Schedule Line 1 (Appears on emails)',
    labelSchedule2: 'Schedule Line 2 (Appears on emails)',
    labelSchedule3: 'Schedule Line 3 (Appears on emails)',
    messageSuccess: 'Store saved successfully',
    notificationEmail: 'Notifications Email Address',
    notificationPhone: 'Notifications Phone Number',
    seeAllStores: 'See all stores',
    save: 'Save',
    saving: 'Saving...',
    smsEnabled: 'Enable SMS notifications when customer arrives',
    titleAdd: 'Add store',
    titleEdit: 'Edit store',
  },
  storesList: {
    addStore: 'Add store',
    confirmDelete: 'Please confirm delete store {{name}} (@{{id}}).',
    errorFetch: 'There was an error fetching the stores.',
    errorDelete: 'Could not delete store',
    deleteStore: 'Delete store @{{id}}?',
    deleteSuccess: 'Store deleted successfully',
    noStores: 'There are no stores yet',
  },
  tenantForm: {
    addAnotherTenant: 'Add another tenant',
    addTenant: 'Add tenant',
    cancel: 'Cancel',
    editTenant: 'Edit tenant',
    labelAccount: 'Account',
    labelCoupon: 'Coupon',
    labelDescription: 'Description',
    labelDomain: 'Domain',
    labelDisplayName: 'Display Name',
    labelLogoUrl: 'Logo URL (include http:// or https://)',
    labelOwnerEmail: 'Tenant Owner Email',
    labelOwnerFirstname: 'Tenant Owner First Name',
    labelOwnerLastname: 'Tenant Owner Last Name',
    labelOwnerUsername: 'Tenant Owner Username',
    labelPrivacyUrl: 'Privacy URL (include http:// or https://)',
    labelSocialFacebook: 'Facebook (include http:// or https://)',
    labelSocialInstagram: 'Instagram (include http:// or https://)',
    labelSocialLinkedin: 'Linkedin (include http:// or https://)',
    labelSocialTwitter: 'Twitter (include http:// or https://)',
    labelSenderEmail: 'Sender Email',
    save: 'Save',
    saving: 'Saving...',
    seeAllTenants: 'See all tenants',
    successMessage: 'Tenant saved successfully',
  },
  userForm: {
    addAnotherUser: 'Add another user',
    cancel: 'Cancel',
    errorFetchingStores: 'Error fetching stores',
    errorSaving: 'Error saving user',
    labelAllStores: 'All stores',
    labelChooseStores: 'Choose stores (max. 3)',
    labelEmail: 'Email',
    labelFirstname: 'First Name',
    labelLastname: 'Last Name',
    labelNone: 'None',
    labelRole: 'Role',
    labelStore: 'Store',
    labelStores: 'Stores',
    labelUsername: 'Username',
    messageSuccess: 'User saved successfully',
    roleAdmin: 'Admin',
    roleEmployee: 'Employee',
    roleOwner: 'Owner',
    seeAll: 'See all users',
    save: 'Save',
    saving: 'Saving...',
    titleAdd: 'Add user',
    titleEdit: 'Edit user',
  },
  usersList: {
    addUser: 'Add user',
    confirmDelete: 'Please confirm delete user {{firstName}} {{lastName}} (@{{username}}).',
    deleteUser: 'Delete user @{{username}}?',
    errorDelete: 'Could not delete user',
    errorFetch: 'There was an error fetching the users.',
    deleteSuccess: 'User deleted successfully',
    noUsers: 'There are no user yet',
    roleAdmin: 'Admin',
    roleEmployee: 'Employee',
    roleOwner: 'Owner',
    roleSysAdmin: 'System Admin',
  },
};
