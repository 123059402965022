import { Auth } from 'aws-amplify';
import { API_NAMES } from './api';

const cognito = {
  REGION: process.env.GATSBY_REGION,
  USER_POOL_ID: process.env.GATSBY_USER_POOL_ID,
  APP_CLIENT_ID: process.env.GATSBY_APP_CLIENT_ID,
  IDENTITY_POOL_ID: process.env.GATSBY_IDENTITY_POOL_ID,
  ENDPOINT_CURBSIDE: process.env.GATSBY_ENDPOINT_CURBSIDE,
  ENDPOINT_USERS: process.env.GATSBY_ENDPOINT_USERS,
}

const amplifyConfig = () => ({
  Auth: {
    mandatorySignIn: false,
    region: cognito.REGION,
    userPoolId: cognito.USER_POOL_ID,
    userPoolWebClientId: cognito.APP_CLIENT_ID,
    identityPoolId: cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: API_NAMES.curbsideAuth,
        endpoint: cognito.ENDPOINT_CURBSIDE,
        region: cognito.REGION,
        custom_header: async () => ({ Authorization: (await Auth.currentSession()).idToken.jwtToken }),
      },
      {
        name: API_NAMES.curbsideAnon,
        endpoint: cognito.ENDPOINT_CURBSIDE,
        region: cognito.REGION,
      },
      {
        name: API_NAMES.usersAuth,
        endpoint: cognito.ENDPOINT_USERS,
        region: cognito.REGION,
        custom_header: async () => ({ Authorization: (await Auth.currentSession()).idToken.jwtToken }),
      },
      {
        name: API_NAMES.usersAnon,
        endpoint: cognito.ENDPOINT_USERS,
        region: cognito.REGION,
      },
    ],
  },
});

export default amplifyConfig;
